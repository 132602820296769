<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
    <!-- <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button> -->

    <app-log-data-dialog module_name="cpar" :id="cpar_header.id">
      <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
    </app-log-data-dialog>

    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableDetailBtn" @click="redirect('Administration.CPAR.Detail', { id: cpar_header.id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
    <app-button :disabled="!enableEditBtn" @click="redirect('Administration.CPAR.Edit', { id: cpar_header.id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
    <!-- <app-button :disabled="!enableUpdateBtn" @click="redirect('Administration.CPAR.Update', { id: cpar_header.id })" title="Update" mdi-icon="mdi-file-document-edit-outline"></app-button> -->
    <app-button :disabled="!enableSetPicBtn" @click="redirect('Administration.CPAR.SetPIC', { id: cpar_header.id })" title="Set PIC" mdi-icon="mdi-account-hard-hat"></app-button>
    <app-button :disabled="!enableDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
    <app-button :disabled="!enableApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
    <app-button :disabled="!enableRejectBtn" @click="rejectData()" :loading="loading_reject" title="Reject Document" mdi-icon="mdi-file-remove" color="red"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <!-- <app-button :disabled="!enableReceiveBtn" @click="receiveData()" :loading="loading_receive" title="Receive Document" mdi-icon="mdi-checkbox-marked-outline"></app-button> -->
    <app-button :disabled="!enableCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
    <app-button :disabled="!enableReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
    <app-button :disabled="!enableReviewBtn" @click="reviewData()" :loading="loading_review" title="Review Document" mdi-icon="mdi-bookmark-check"></app-button>
    <set-close-document :id="parseInt(cpar_header.id)" @callback="refreshDetail(true)">
      <template v-slot:activator="{ on }">
        <app-button :disabled="!enableCloseBtn" v-on:click="on" :loading="loading_close_document" title="Closed & Verify Document" mdi-icon="mdi-book-check"></app-button>
      </template>
    </set-close-document>
    <app-button :disabled="!enableUnSubmitBtn" @click="unSubmitData()" :loading="loading_unsubmit" title="Un-Submit Document" mdi-icon="mdi-book-cancel" color="error"></app-button>
  </div>
</template>

<script>
import SetCloseDocument from "./SetCloseDocument.vue";
export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
  },
  components: { SetCloseDocument },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      // loading_receive: false,
      loading_cancel: false,
      loading_attachment: false,
      loading_reject: false,
      loading_unsubmit: false,
      loading_review: false,
      data_detail: {},
    }
  },
  watch: {
    dataDetail() {
      this.data_detail = this.dataDetail;
    }
  },
  computed: {
    cpar_header() {
      return (typeof this.dataDetail.cpar_header != 'undefined') ? this.dataDetail.cpar_header : {};
    },
    cpar_approval() {
      return (typeof this.dataDetail.cpar_approval != 'undefined') ? this.dataDetail.cpar_approval : {};
    },
    enableDetailBtn() {
      if (this.$route.name !== 'Administration.CPAR.Detail'){        
        return true;
      }
      return false;
    },
    enableDeleteBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "approved"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "delete") && !this.inArray(this.cpar_header.status, ["approved"]) && this.inArray(this.cpar_header.status, ["open", "cancel"])) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel", "open"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "reopen") && !this.inArray(this.cpar_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "cancel") && !this.inArray(this.cpar_header.status, ["approved"]) && !this.inArray(this.cpar_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "send-approval") && (this.cpar_header.status == "open" || this.cpar_header.status == "rejected")) {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (!this.inArray(this.cpar_header.status, ["waiting-approval"])) return false;

      if (this.cpar_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.cpar_approval !== "undefined") {
          if (this.cpar_approval.length >= 0) {
            this.cpar_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableRejectBtn() {
      if (!this.inArray(this.cpar_header.status, ["waiting-approval"])) return false;

      if (this.cpar_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.cpar_approval !== "undefined") {
          if (this.cpar_approval.length >= 0) {
            this.cpar_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "modify") && this.cpar_header.status == "open") {
        return true;
      }
      return false;
    },
    enableUpdateBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "update") && this.cpar_header.status == "approved" && (this.cpar_header.substatus != "" && this.cpar_header.substatus != null)) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;
      if (!this.inArray(this.cpar_header.substatus, ["", "submitted"])) return false;

      if (this.modulePermission("cpar", "close")) {
        return true;
      }

      return false;
    },
    enableReviewBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;
      if (!this.inArray(this.cpar_header.substatus, ["", "submitted"])) return false;

      if (this.modulePermission("cpar", "review")) {
        return true;
      }

      return false;
    },
    enableSetPicBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (this.modulePermission("cpar", "set-pic") && this.cpar_header.status == "approved") {
        return true;
      }
      return false;
    },
    enableUnSubmitBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;
      if (this.inArray(this.cpar_header.substatus, ["", "on-process"])) return false;

      if (this.modulePermission("cpar", "un-submit")) {
        return true;
      }
      return false;
    },
    // enableReceiveBtn() {
    //   if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

    //   if (this.modulePermission("cpar", "receive") && this.cpar_header.received == false) {
    //     return true;
    //   }

    //   return false;
    // },
  },
  methods: {

    refreshDetail() {
      this.$emit('refresh-detail')
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel/Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("cpar/delete/" + this.cpar_header.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.$emit("callback-delete");
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },

    /**
     * cancelData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_send_approval = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/send-approval", formData)
          .then((res) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },

    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    rejectData() {
      let post = async () => {
        this.loading_reject = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/reject", formData)
          .then((res) => {
            this.loading_reject = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reject = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Reject document?", post);
    },

    /**
     * reopenData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },

    /**
     * closeData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    closeData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/close", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Close document?", post);
    },


    unSubmitData() {
      let post = async () => {
        this.loading_unsubmit = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/un-submit", formData)
          .then((res) => {
            this.loading_unsubmit = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_unsubmit = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Un-Submit document?", post);
    },


    reviewData() {
      let post = async () => {
        this.loading_review = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/review", formData)
          .then((res) => {
            this.loading_review = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_review = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Un-Submit document?", post);
    },

    // receiveData() {
    //   let post = async () => {
    //     this.loading_receive = true;
    //     this.showLoadingOverlay(true);
    //     const formData = new FormData();
    //     formData.append("id", this.cpar_header.id);
    //     await this.$axios
    //       .post("cpar/receive", formData)
    //       .then((res) => {
    //         this.loading_receive = false;
    //         this.showLoadingOverlay(false);
    //         this.showAlert(res.data.status, res.data.message);

    //         this.refreshDetail(true);
    //       })
    //       .catch((error) => {
    //         this.loading_receive = false;
    //         this.showLoadingOverlay(false);
    //         this.axiosErrorHandler(error);
    //       });
    //   }
    //   this.showConfirm("Confirm", "Receive document?", post);
    // },
  }
}
</script>